import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { useStaticContext } from 'src/hooks/useStaticContext';
import { slugify } from 'src/util/slugify';
import {
  ICustomGiftlistWrapper,
  IGiftListTags,
} from 'typings/generated/contentful';

interface Props {
  tag: IGiftListTags;
  selected: boolean;
}

const TagFilterButton = ({ tag, selected }: Props) => {
  const context = useStaticContext();
  const giftListWrapper: ICustomGiftlistWrapper = context.customGiftlistWrapper;

  const link = selected
    ? `/${giftListWrapper.fields.slug}`
    : `/${giftListWrapper.fields.slug}/${slugify(tag.fields.name)}`;
  return (
    <Link
      className={cx('p-2 border m-1 rounded border-x-dark-blue', {
        'bg-x-dark-blue ': selected,
        ' hover:bg-gray-100': !selected,
        'text-white': selected,
      })}
      to={link}
    >
      {tag.fields.name}
    </Link>
  );
};

export default TagFilterButton;
