import React, { useCallback } from 'react';
import ListCard from 'src/components/gift-list/list-card';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/misc/seo';
import Container from 'src/components/widget/container';
import Divider from 'src/components/widget/divider';
import PageHeader from 'src/components/widget/page-header';
import TagFilterButton from 'src/components/widget/tag-filter-button';
import WideContainer from 'src/components/widget/wide-container';
import { StaticContextType } from 'typings/custom';
import {
  ICustomGiftlist,
  ICustomGiftlistWrapper,
  IGiftListTags,
} from 'typings/generated/contentful';

interface Props {
  pageContext: {
    reactContext: StaticContextType;
    wrapper: ICustomGiftlistWrapper;
    giftLists: ICustomGiftlist[];
    tag: IGiftListTags | undefined;
    tags: IGiftListTags[];
  };
}

const ListFeedPage = ({ pageContext }: Props) => {
  const {
    reactContext,
    tag: selectedTag,
    giftLists,
    tags,
    wrapper: {
      fields: { title, description },
    },
  } = pageContext;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderTags = useCallback(() => {
    <div className="flex flex-wrap pb-8 -mx-1 lg:justify-between">
      {(tags || []).map((tag) => (
        <TagFilterButton
          key={tag.sys.id}
          selected={!!selectedTag && selectedTag.sys.id === tag.sys.id}
          tag={tag}
        />
      ))}
    </div>;
  }, []);

  return (
    <Layout context={reactContext}>
      <SEO description={description} title={title} />
      <div className="flex flex-col items-center">
        <Container>
          <PageHeader description={description} title={title} />
          <div className="grid grid-cols-1 gap-4 md:mx-0 md:grid-cols-3">
            {(giftLists || []).map((giftList, i, list) => (
              <div className="relative" key={giftList.sys.id}>
                <ListCard assetWidth={700} expanded giftList={giftList} />
                {i < list.length - 1 && (
                  <WideContainer>
                    <Divider />
                  </WideContainer>
                )}
              </div>
            ))}
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default ListFeedPage;
